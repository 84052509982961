import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { ApiService } from '../../core/services/index';

@Injectable({ providedIn: 'root' })
export class adminService {

    constructor(private _api: ApiService) { }

    //login user
    loginUser(data) {
        return this._api.post(`${"admin/login"}`, data)
            .pipe(
                map((res: any) => res),
                catchError((error: any) => error)
            );
    }

    //get user by id
    getUserById(id: string) {
        return this._api.get(`${"admin/byId/" + id}`,)
            .pipe(
                map((res: any) => res),
                catchError((error: any) => error)
            );
    }

}

